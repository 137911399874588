import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Link as TextLink } from '@mui/material'
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

function InformationAccordion() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandCollapse = () => {
    setExpanded(!expanded);
  };

  const handleTutorial = () => {
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: '#topics-field', popover: {
            title: 'Topics Field',
            description: 'Use this field to enter the topics you want to generate questions for. Separate each topic with a new line.'
          }
        },
        {
          element: '#number-field', popover: {
            title: 'Number of Questions Field',
            description: 'Use this field to enter the number of questions you want to generate. You can generate 1 to 50 questions at a time.'
          }
        },
        {
          element: '#generate', popover: {
            title: 'Generate Button',
            description: 'Click this button to generate the quiz. You can also click this button to stop generating questions when it turns to a "Stop" button.'
          }
        },
      ]
    });

    driverObj.drive();
  }

  return (
    <Box sx={{ margin: "10px auto", maxWidth: "500px" }}>
      <Typography>
        Test Me generates quizzes for you using AI to test your knowledge on topics you input.
        {expanded ? (
          <>
            <br /><br/>
            You can input 1 to 5 topics in the topics field. The topics should be on different lines.
            <br /><br/>
            You can input 1 to 50 questions in the # Questions field for the number of questions you want the quiz to be.
            <br /><br/>
            Free users can generate 10 quizzes per day. Premium users can generate 100 quizzes per day. You can
            subscribe to premium by going to the <Link to="/settings"><TextLink>Settings</TextLink></Link> page and clicking
            the <Box component="span" fontWeight='fontWeightBold'>Subscribe</Box> button.
          </>
        ) : null}
      {expanded && (
        <Button
          variant="outlined"
          size="large"
          sx={{ display: "block", mt: 2, margin: "10px auto 0" }}
          onClick={handleTutorial}
        >
          Open Tutorial
        </Button>
      )}
      </Typography>
      <Button
        variant="contained"
        sx={{ display: "block", margin: '12px auto'}}
        onClick={handleExpandCollapse}
      >
        {expanded ? 'Read Less' : 'Read More'}
      </Button>
    </Box>
    
  )
}

export default InformationAccordion;
