import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LightMode from '@mui/icons-material/LightMode';
import DarkMode from '@mui/icons-material/DarkMode';
import MenuIcon from '@mui/icons-material/Menu';
import { Theme } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import Menu from '@mui/material/Menu'; // Import Menu component
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem component
import Link from '@mui/material/Link';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface NavbarProps {
  toggleDarkMode: () => void;
  logOut: () => void;
  logIn: () => void;
  loggedIn: boolean;
  theme: Theme;
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ScrollTop(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 20, right: 20 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default function Navbar({ toggleDarkMode, logOut, logIn, theme, loggedIn }: NavbarProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for Menu

  function navigateSettings() {
    navigate("/settings");
  }

  function navigateHomeAndLogout() {
    navigate("/");
    logOut();
  }

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="box" position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} id="back-to-top-anchor">
          <Typography variant="h6" component="div" sx={{ cursor: 'pointer', flexGrow: 1 }} onClick={() => navigate("/")}>
            Test Me
          </Typography>

          <Tooltip title={theme.palette.mode === 'dark' ? "Switch to light mode" : "Switch to dark mode"}>
            <IconButton onClick={toggleDarkMode} color="inherit">
              {theme.palette.mode === 'light' ? <DarkMode /> : <LightMode />}
            </IconButton>
          </Tooltip>
          <Hidden smDown>
            <Button href="https://forms.gle/5KahWzj1ti5NC8YB8" color="inherit" target='_blank'>
              Feedback
            </Button>
            {loggedIn ? (
              <>
                <Button color="inherit" onClick={navigateSettings}>
                  Settings
                </Button>
                <Button color="inherit" onClick={navigateHomeAndLogout}>
                  Log Out
                </Button>
              </>
            ) : (
              <Button color="inherit" onClick={logIn}>
                Login
              </Button>
            )}
          </Hidden>

          <Hidden smUp>
            <IconButton color="inherit" onClick={toggleMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              <MenuItem onClick={closeMenu}>
                <Link href="https://forms.gle/5KahWzj1ti5NC8YB8" target='_blank' rel="noopener" color="inherit" underline="none">
                  Feedback
                </Link>
              </MenuItem>
              {loggedIn ? (
                <>
                  <MenuItem onClick={() => {
                    navigateSettings();
                    closeMenu();
                  }}>Settings</MenuItem>
                  <MenuItem onClick={() => {
                    navigateHomeAndLogout();
                    closeMenu();
                  }}>Log Out</MenuItem>
                </>
              ) : (
                <MenuItem onClick={() => {
                  logIn();
                  closeMenu();
                }}>Login</MenuItem>
              )}
            </Menu>
          </Hidden>
        </Toolbar>
      </AppBar>
      <ScrollTop>
        <Fab size="large" aria-label="scroll back to top" color='primary'>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
  );
}
