import getBackendURL from "./config";

export interface Question {
    question_text: string;
    explanation: string;
    correct_answer: number;
    choices: string[];
    selected_choice_index?: number;
}

export async function getQuestions(numQuestions: number, topic: string, userId: string): Promise<Question[]> {
    try {
        console.log("Num questions: " + numQuestions);
        const backendURL = getBackendURL();
        const response = await fetch(`${backendURL}/questions?topic=${topic}&num_questions=${numQuestions}&user_id=${userId}`);
        if (!response.ok) {
            throw new Error("Failed to fetch questions");
        }
        const questions = await response.json();
        return questions;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getQuestionsStreaming(
    setQuestions: React.Dispatch<React.SetStateAction<Question[]>>, // Function to set the questions
    numQuestions: number,
    topic: string,
    userId: string
): Promise<void> {
    try {
        const backendURL = getBackendURL();
        const response = await fetch(`${backendURL}/questions_streaming?topic=${topic}&num_questions=${numQuestions}&user_id=${userId}`);
        if (!response || !response.body) {
            throw new Error("Failed to fetch questions");
        }
        const reader = response.body.getReader();
        let numLines = 0;
        let lines = [];

        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                break;
            }

            // Process the received data as a string
            const line = new TextDecoder().decode(value);
            lines.push(line);
            console.log(line)
            // console.log(lines)

            // Split the text into individual questions by new lines
            numLines += 1

            if (numLines === 7) {
                numLines = 0;
                let newQuestion: Question = {
                    question_text: lines[0],
                    choices: [lines[1], lines[2], lines[3], lines[4]],
                    correct_answer: parseInt(lines[5]),
                    explanation: lines[6],
                }
                console.log(newQuestion);
                lines = [];
                setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
            }
        }
    } catch (error) {
        console.error("Error fetching questions:", error);
    }
}
