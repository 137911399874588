import { Box, Typography } from '@mui/material';
import { UserProfile } from '../utils/models';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface BodyProps {
  profile: UserProfile | null;
  premiumUser: boolean;
  nextBillingDate: string;
  userIdRef: React.MutableRefObject<string>;
  backendURL: string;
  trialEnding: boolean;
}

function Settings({ profile, premiumUser, nextBillingDate, userIdRef, backendURL, trialEnding }: BodyProps) {
  const navigate = useNavigate();

  function navigateHome() {
    navigate("/");
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
      <Box sx={{ maxWidth: 500, width: '100%', paddingLeft: '20px' }}>
        <Button startIcon={<ArrowBack />} onClick={navigateHome} sx={{ mb: 1 }}>
          Home
        </Button>
        <Typography variant="h4" component="div" sx={{ mb: "20px" }}>
          Settings
        </Typography>
        <Avatar
          alt={profile?.given_name + " " + profile?.family_name}
          src={profile?.picture}
          sx={{ width: 56, height: 56, marginBottom: "10px" }}
        />
        <Typography style={{ marginBottom: "10px" }}>
          {profile?.given_name + " " + profile?.family_name}
        </Typography>
        {premiumUser ? (
          trialEnding ? (
            <Typography sx={{ mb: "20px" }}>
              Trial Ends: {nextBillingDate}
            </Typography>
          ) : (
            <>
              <Typography sx={{ mb: "20px" }}>
                Next Billing Date: {nextBillingDate}
              </Typography>
              <form action={`${backendURL}/cancel-subscription/${userIdRef.current}`} method="POST">
                <Button variant="contained" color="error" type="submit">Cancel Subscription</Button>
              </form>
            </>
          )
        ) : (
          <form action={`${backendURL}/create-checkout-session?client_id=${userIdRef.current}`} method="POST">
            <Button type="submit" variant="contained" color="primary">
              Subscribe
            </Button>
          </form>
        )}
      </Box>
    </Box>
  )
}

export default Settings;
