import { useState, useRef } from 'react';
import { getQuestionsStreaming } from '../utils/api';
import { Question } from '../utils/api';
import { QuestionItem } from './QuestionItem';
import { Alert, Skeleton, Typography } from '@mui/material';
import { Button, TextField, CircularProgress } from '@mui/material';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InformationAccordion from './InformationAccordion';

import './Body.scss';

interface BodyProps {
  remainingRequests: number;
  loggedIn: boolean;
  setRemainingRequests: (numRequest: number) => void;
  userId: string;
  logIn: () => void;
  lastResetDate: string;
  backendURL: string;
  userIdRef: React.MutableRefObject<string>;
  premiumUser: boolean;
  loaded: boolean;
}

function Body({ remainingRequests, setRemainingRequests, loggedIn, userId, logIn, lastResetDate, backendURL, userIdRef, premiumUser, loaded }: BodyProps) {
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  loadingRef.current = loading;
  const [questions, setQuestions] = useState<Question[]>([]);
  const [topicError, setTopicError] = useState(false);
  const [numQuestionsError, setNumQuestionsError] = useState(false);

  const handleGenerate = async (event: React.FormEvent<HTMLFormElement>) => {
    setQuestions([]);
    event.preventDefault();

    if (loadingRef.current) {
      setLoading(false);
      loadingRef.current = false;
      setQuestions([]);
      return;
    }

    setLoading(true);
    loadingRef.current = true;

    if (remainingRequests === 0) {
      alert('Ran out of requests for today');
      console.log(loaded);
    }

    // Reset error states
    setTopicError(false);
    setNumQuestionsError(false);

    const formData = new FormData(event.currentTarget);
    let topic = formData.get('topics') as string;
    topic = topic.trim().replace(/\n+/g, ' and ')

    const numQuestions = Number(formData.get('numQuestions'));

    let hasError = false;

    // Validate topics field
    if (!topic) {
      setTopicError(true);
      hasError = true;
    }

    // Validate numQuestions field
    if (isNaN(numQuestions) || numQuestions <= 0 || numQuestions > 50) {
      setNumQuestionsError(true);
      hasError = true;
    }

    // Stop further execution if there are errors
    if (hasError) {
      return;
    }

    const radioInputs = document.querySelectorAll('input[type="radio"]:checked');
    radioInputs.forEach((input: Element) => {
      const radioInput = input as HTMLInputElement;
      radioInput.checked = false;
    });

    //setLoading(true);
    setRemainingRequests(remainingRequests - 1);
    await getQuestionsStreaming(setQuestions, numQuestions, topic, userId);

    setLoading(false);
    loadingRef.current = false;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const questionIndex = parseInt(event.target.name.split(' ')[1]);
    const choiceIndex = parseInt(event.target.name.split(' ')[2]);

    const updatedQuestions = questions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          selected_choice_index: choiceIndex,
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
  };

  function timeStampToHoursAndMinutes(timestampStr: string) {
    const lastResetDate = new Date(timestampStr);

    // Add one day to lastResetDate
    lastResetDate.setDate(lastResetDate.getDate() + 1);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = lastResetDate.getTime() - currentDate.getTime();

    // Convert the time difference to minutes
    const totalMinutes = timeDifference / (1000 * 60);

    // Calculate the hours and remaining minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);

    // Output the result
    return `You can generate requests again in ${hours} hours and ${minutes} minutes`;
  }

  return (
    <div className="body">
      {loggedIn ? (
        loaded ? (
          <div>
          <InformationAccordion />
          <form onSubmit={handleGenerate} className="form">
            <div className="inputs">
              {remainingRequests === 0 ? (
                <>
                  <Alert severity='error' sx={{ width: '100%' }}>
                    You have run out of requests for today. {timeStampToHoursAndMinutes(lastResetDate)}.
                  </Alert>
                  {premiumUser ? (
                    null
                  )
                  :
                  (
                    <Typography sx={{ mb: 2, }} variant="subtitle1">
                      To get more requests, subscribe to premium.
                      <form action={`${backendURL}/create-checkout-session?client_id=${userIdRef.current}`} method="POST">
                        <Button type="submit" variant="contained" color="success" sx={{width: '100%', mt: 1}}>
                          Subscribe
                        </Button>
                      </form>
                    </Typography>
                  )}
                </>
              )
                :
                (
                  <>
                    <TextField
                      className="topic-field"
                      id="topics-field"
                      placeholder="Enter quiz topics separated by a new line"
                      multiline
                      rows={4}
                      label="Topics"
                      name="topics"
                      variant="filled"
                      fullWidth
                      error={topicError} // Add error prop
                      helperText={topicError ? "Please enter at least one topic." : " "} // Add helperText prop
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      className="number-field"
                      id="number-field"
                      placeholder="Enter 1 to 50 for number of questions to generate"
                      type="number"
                      name="numQuestions"
                      label="# Questions"
                      variant="filled"
                      fullWidth
                      error={numQuestionsError} // Add error prop
                      helperText={
                        numQuestionsError
                          ? "Please enter a number between 0 and 50."
                          : " "
                      }
                    />
                    <Typography sx={{ mb: 2 }} variant="subtitle2">
                      Remaining requests today: {remainingRequests}
                    </Typography>
                    {loading ? (
                      <Button color="error" type="submit" variant="contained" size="large">
                        Stop
                      </Button>
                    ) : (
                      <Button id="generate" color="primary" type="submit" variant="contained" size="large" disabled={remainingRequests === 0}>
                        Generate Quiz
                      </Button>
                    )}
                  </>
                )}
            </div>
            <div className="questions">
              {questions.map((question, question_index) => (
                <>
                  <QuestionItem key={question_index} question={question} question_index={question_index} handleChange={handleChange} />
                  <Divider sx={{ mb: 1, width: '100%' }} />
                </>
              ))}
              {loading ? (
                <>
                  <Skeleton variant="rounded" animation="wave" height={32} style={{ marginBottom: '5px' }} />
                  <Skeleton variant="rounded" animation="wave" height={180} />
                </>
              ) : null}
            </div>
          </form>
        </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        )
      ) : (
        <Typography variant="h5" align="center" sx={{ mt: 1 }}>
          Please <Link component="button" onClick={logIn}>sign in</Link> to continue
        </Typography>
      )}
    </div>
  );
}

export default Body;
