import './App.css';
import Navbar from './components/Navbar';
import Body from './components/Body';
import { useEffect, useCallback } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { useState, useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { TokenResponse } from '@react-oauth/google';
import axios from 'axios';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import getBackendURL from './utils/config';
import Settings from './components/Settings';
import { UserProfile } from './utils/models';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [remainingRequests, setRemainingRequests] = useState(1);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const userIdRef = useRef<string>('');
  const [premiumUser, setPremiumUser] = useState(false);
  const [trialEnding, setTrialEnding] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [nextBillingDate, setNextBillingDate] = useState('');
  const [lastResetDate, setLastResetDate] = useState('');
  const accessTokenRef = useRef<string>('');
  const backendURL = getBackendURL();

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const getRemainingRequests = useCallback(() => {
    axios
      .get(`${backendURL}/get_remaining_requests/${userIdRef.current}?access_token=${accessTokenRef.current}`)
      .then((res) => {
        setRemainingRequests(res.data.remaining_requests);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, [backendURL]);

  const logIn = useGoogleLogin({
    onSuccess: (codeResponse: Omit<TokenResponse, "error" | "error_description" | "error_uri">) => {
      localStorage.setItem('user', JSON.stringify(codeResponse));
      accessTokenRef.current = codeResponse.access_token;
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          setProfile(res.data);
          setLoggedIn(true);
          userIdRef.current = res.data.id;
          getRemainingRequests();
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    // Checks for stored profile in local storage
    const storedProfile = localStorage.getItem('user');
    if (storedProfile) {
      const parsedProfile = JSON.parse(storedProfile) as TokenResponse;
      accessTokenRef.current = parsedProfile.access_token;
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${parsedProfile.access_token}`, {
          headers: {
            Authorization: `Bearer ${parsedProfile.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          setLoggedIn(true);
          setProfile(res.data);
          userIdRef.current = res.data.id
          // sets the remaining requests if user is found in local storage
          axios.get(`${backendURL}/user/${userIdRef.current}`)
            .then((res) => {
              setLastResetDate(res.data.last_reset);
              setPremiumUser(res.data.premium_user);
              setNextBillingDate(res.data.next_billing_date);
              setTrialEnding(res.data.trial_ending);
            })
          getRemainingRequests();
          
        })
        .catch((err) => console.log(err));
    }
  }, [backendURL, getRemainingRequests]);

  const logOut = () => {
    googleLogout();
    localStorage.removeItem('user');
    setLoggedIn(false);
    setProfile(null);
  };

  return (
    <ThemeProvider theme={darkMode ? lightTheme : darkTheme}>
      <CssBaseline />
      <HashRouter>
        <Routes>
          <Route path="/settings" element={
            <>
              <Navbar
                toggleDarkMode={toggleDarkMode}
                theme={darkMode ? lightTheme : darkTheme}
                logOut={logOut}
                logIn={logIn}
                loggedIn={loggedIn} />
              <Settings
                profile={profile}
                premiumUser={premiumUser}
                nextBillingDate={nextBillingDate}
                userIdRef={userIdRef}
                backendURL={backendURL}
                trialEnding={trialEnding} />
            </>
          } />
          <Route path="/" element=
            {<>
              <Navbar
                toggleDarkMode={toggleDarkMode}
                theme={darkMode ? lightTheme : darkTheme}
                logOut={logOut} logIn={logIn}
                loggedIn={loggedIn} />
              <Body
                remainingRequests={remainingRequests}
                setRemainingRequests={setRemainingRequests}
                loggedIn={loggedIn}
                userId={userIdRef.current}
                logIn={logIn} lastResetDate={lastResetDate}
                backendURL={backendURL}
                userIdRef={userIdRef}
                premiumUser={premiumUser}
                loaded={loaded}
              />
            </>}
          />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
