import { Question } from '../utils/api';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';

interface QuestionItemProps {
  question: Question;
  question_index: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function QuestionItem({ question, question_index, handleChange }: QuestionItemProps) {
  return (
    <Box key={question_index} marginBottom={1}>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {question.question_text}
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={handleChange}
        >
          {question.choices.map((choice, choice_index) => (
            <div key={`${question_index}${choice_index}`}>
              <FormControlLabel value={choice} control={<Radio size="small" />} label={choice} name={`q ${question_index} ${choice_index}`} />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
      {question.selected_choice_index !== undefined ? (
        question.correct_answer === question.selected_choice_index ? (
          <Alert severity="success">Correct! {question.explanation}</Alert>
        ) : (
          <Alert severity="error">Incorrect</Alert>
        )
      ) : null}
    </Box>
  )
}
